import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
	container: {},
	infoText: {
		color: 'black',
	},
	image: {
		height: 200,
		width: '100%',
	},
});

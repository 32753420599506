export const ENV = {
  FIREBASE_CONFIG: {
    apiKey: "AIzaSyAYtb1rAoK79tFFCsZQ2xE1Sst1J6B9cSo",
    authDomain: "itinerary-4aee3.firebaseapp.com",
    projectId: "itinerary-4aee3",
    storageBucket: "itinerary-4aee3.appspot.com",
    messagingSenderId: "531959047364",
    appId: "1:531959047364:web:97fb0d5af96b4220855591",
    measurementId: "G-SH76NQ1HFZ",
  },
  GCP_API_KEY_WEB: "AIzaSyDJllV2mGPTXQq9zoLz1jBy79zgQqO5IDk",
  BACKEND_PLACE_ENDPOINT:
    "https://spelieve-backend-place-r4qxqxf4ta-an.a.run.app/",
  PROXY_SERVER_URL: "",
  LOGGER: false,
  HOST_NAME_WEB: "https://www.spelieve.com",
  ADMOB_IOS_Banner_UNIT_ID: "ca-app-pub-8992436220024710/5398683210",
  ADMOB_ANDROID_Banner_UNIT_ID: "ca-app-pub-8992436220024710/2040509245",
};
